<template>
  <div class="app-container v">
    <div class="head-container">
      <quick-select v-model="query.sellerId" filterable url="api/purchase/seller" placeholder="供应商" value-field="belongTo" display-field="belongToName" clearable @change="handleSellerChange" class="filter-item" style="width: 180px;" auto-select-first-option />
      <quick-select v-model="query.buyerErpId" filterable url="api/distributorErps/list" placeholder="下单账户" value-field="erpId" display-field="name" @change="toQuery" class="filter-item" style="width: 180px;" auto-select-first-option />
      <el-date-picker v-model="query.dateRange" type="daterange" class="filter-item" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="searchDateRangeOptions" value-format="timestamp" :default-time="['00:00:00', '23:59:59']" @change="toQuery" />
      <el-input v-model="query.docNum" class="filter-item" :maxlength="20" placeholder="单号搜索" @keypress.enter.native="toQuery" clearable style="width: 180px;" />
      <el-button class="filter-item" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-download" :loading="downloadLoading" @click="toDownload">导出</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    </div>
    <el-table v-loading="loading" row-key="id" :data="data" height="200px">
      <el-table-column prop="formCode" label="单号" width="140" />
      <el-table-column label="单据日期" width="100" :formatter="r => { return new Date(r.realSendTime||r.createAt).format('yyyy/MM/dd'); }" />
      <el-table-column prop="goodsPriceAmount" label="单据金额" width="120" align="right" :formatter="$price" v-if="checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])" />
      <el-table-column prop="discount" label="折扣金额" width="120" align="right" :formatter="$price" v-if="checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])" />
      <el-table-column prop="receiverName" label="收货人" />
      <el-table-column prop="inStockType" label="是否已入库">
        <template slot-scope="scope">
          <span v-if="scope.row.inStockType =='NONE'">未入库</span>
          <span v-else-if="scope.row.inStockType =='PART'">部分入库</span>
          <span v-else>已入库</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="收货地址" min-width="240" show-overflow-tooltip>
        <template slot-scope="scope">{{scope.row.recProvince}}{{scope.row.recDistrict}}{{scope.row.recCity}}{{scope.row.recAddress}}</template>
      </el-table-column>-->
      <el-table-column width="50" fixed="right">
        <div class="row-commands" slot-scope="scope">
          <el-button size="mini" type="text" @click="view(scope.row)">查看</el-button>
        </div>
      </el-table-column>
    </el-table>
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="prev, pager, next, sizes,jumper" @size-change="sizeChange" @current-change="pageChange" />
    <eForm ref="form" />
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";
import eForm from "./form";

export default {
  mixins: [initData],
  components: { eForm },
  data() {
    return {
      downloadLoading: false,
      searchDateRangeOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      query: {
        sellerId: null,
        dateRange: null,
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = "api/orderSendForm/erp/page";
      let query = JSON.parse(JSON.stringify(this.query));
      if (query.dateRange && query.dateRange.length === 2) {
        query.startDate = query.dateRange[0];
        query.endDate = query.dateRange[1];
      }
      delete query.dateRange;
      this.params = Object.assign(
        { status: query.status, formType: 0, sort: "createAt,desc" },
        query
      );
      return true;
    },
    handleSellerChange() {
      this.toQuery();
    },
    view(data) {
      this.$refs.form && this.$refs.form.resetForm(data);
    },
    toDownload() {
      if (!this.data || (this.data && this.data.length === 0)) {
        this.$message.info("当前无数据导出");
        return;
      }
      this.downloadLoading = true;
      this.params.page = 0;
      this.params.size = this.total;
      download("api/orderSendForm/erp/download", this.params)
        .then((result) => {
          downloadFile(result, "发货明细", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
  },
};
</script>